/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
// import { init, identify, Identify } from "@amplitude/analytics-browser";
import usePrevious from "./hooks/usePrevious";
import { successVar, errorsVar, userVar } from "./cache/cache";
import { useAuthUserLazyQuery, useUserInfoLazyQuery } from "./types/graphql";
import { getAccessToken, setAccessToken, setRefreshCookie } from "./misc/common";
import UpdateDuelsInfo from "./popup/UpdateDuelsInfo";
import CookiePolicy from "./components/common/CookiePolicy";
import CookiesPolicy from "./popup/CookiesPolicy";
import DeleteGroup from "./popup/DeleteGroup";
import AddUserInGroup from "./popup/AddUserInGroup";
import DeleteUserFromGroup from "./popup/DeleteUserFromGroup";

const Login = React.lazy(() => import("./pages/Login"));
const Lobby = React.lazy(() => import("./pages/Lobby"));
const History = React.lazy(() => import("./pages/History"));
const UsersGroup = React.lazy(() => import("./pages/UsersGroup"));
const Users = React.lazy(() => import("./pages/Users"));
const Tasks = React.lazy(() => import("./pages/Tasks"));
const Duel = React.lazy(() => import("./pages/Duel"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));
const Instruction = React.lazy(() => import("./pages/Instruction"));
const Settings = React.lazy(() => import("./pages/SettingsPage"));
const Transactions = React.lazy(() => import("./pages/Transactions"));

const Popup = React.lazy(() => import("./components/common/Popup"));
const ManageGroup = React.lazy(() => import("./popup/ManageGroup"));
const ManageUser = React.lazy(() => import("./popup/ManageUser"));
const ManageTask = React.lazy(() => import("./popup/ManageTask"));
const ManageInstruction = React.lazy(() => import("./popup/ManageInstruction"));
const TaskInfo = React.lazy(() => import("./popup/TaskInfo"));
const DuelInfo = React.lazy(() => import("./popup/DuelInfo"));
const DuelVideo = React.lazy(() => import("./popup/DuelVideo"));
const TaskVote = React.lazy(() => import("./popup/TaskVote"));
const SendComplaint = React.lazy(() => import("./popup/SendComplaint"));
const PlayerAnswer = React.lazy(() => import("./popup/PlayerAnswer"));
const OrganizeDuel = React.lazy(() => import("./popup/OrganizeDuel"));
const CancelVerification = React.lazy(() => import("./popup/CancelVerification"));
const ManageEnglishName = React.lazy(() => import("./popup/ManageEnglishName"));
const EditPurchase = React.lazy(() => import("./popup/EditPurchase"));
const DiplomsModal = React.lazy(() => import("./popup/Diploms"));

const Diplomas = React.lazy(() => import("./pages/Diplomas"));

const App: React.FC = () => {
    let user = useReactiveVar(userVar);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const temporaryToken = urlParams.get("token");
    const recorderToken = urlParams.get("authToken");
    const cookiesAccepted = localStorage.getItem("cookiesAccepted") ? true : false;

    useEffect(() => {
        const token = getAccessToken();
        if (token === null && temporaryToken === null && recorderToken === null) {
            window.location.href = `${process.env.REACT_APP_AUTH_DOMAIN}?from=${process.env.REACT_APP_DOMAIN}`;
        }
        if (temporaryToken !== null) {
            authUserQuery({
                variables: {
                    accessToken: temporaryToken as string,
                },
            });
        }
        if (recorderToken) {
            setAccessToken(recorderToken);
        }
        if (token || recorderToken) {
            userQuery();
        }
    }, []);

    const [authUserQuery, { loading: authLoading }] = useAuthUserLazyQuery({
        onCompleted(data) {
            setAccessToken(data?.authUser?.accessToken as string);
            setRefreshCookie(data?.authUser?.refreshToken as string);
            userQuery();
        },
        onError(error) {
            window.location.href = `${process.env.REACT_APP_AUTH_DOMAIN}?from=${process.env.REACT_APP_DOMAIN}`;
        },
    });

    const [userQuery] = useUserInfoLazyQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            userVar(data?.userInfo || null);
            // if (process.env.REACT_APP_STAGE === "production") {
            //   init(
            //     "dce6cd423b1b80a55ac9c5760de6d78e",
            //     String(data?.userInfo?.code) || "unknown",
            //   );
            // }
            // const identity = new Identify();
            // identity.set("email", String(data?.userInfo?.email));
            // identity.set("code", String(data?.userInfo?.code));
            // identity.set("id", String(data?.userInfo?.id));
            // identity.set(
            //   "group_ids_string",
            //   (data?.userInfo?.groupIds || []).join(", "),
            // );
            // identity.set("role", String(data?.userInfo?.role));
            // identity.set("type", String(data?.userInfo?.type));
            // identify(identity);
        },
        onError: (err) => {
            console.log("OnError", err);
        },
        errorPolicy: "ignore",
    });

    if (authLoading && user === undefined) {
        return <div className="preloader"></div>;
    }

    return (
        <div className="App">
            <BrowserRouter>
                <React.Suspense fallback={<div className="preloader"></div>}>
                    <Routes>
                        <Route
                            path={"/"}
                            element={<Login />}
                        />
                        <Route
                            path={"/lobby/:duelId"}
                            element={<Lobby />}
                        />
                        <Route
                            path={"/lobby"}
                            element={<Lobby />}
                        />
                        <Route
                            path={"/history"}
                            element={<History />}
                        />
                        <Route
                            path={"/users"}
                            element={<UsersGroup />}
                        />
                        <Route
                            path={"/users/:groupId"}
                            element={<Users />}
                        />
                        <Route
                            path={"/tasks"}
                            element={<Tasks />}
                        />
                        <Route
                            path={"/duel/:duelId/*"}
                            element={<Duel />}
                        />
                        <Route
                            path={"/profile/:userId"}
                            element={<UserProfile />}
                        />
                        <Route
                            path={"/instruction"}
                            element={<Instruction />}
                        />
                        <Route
                            path={"/settings"}
                            element={<Settings />}
                        />
                        <Route
                            path={"/diplomas"}
                            element={<Diplomas />}
                        />
                        <Route
                            path={"/transactions"}
                            element={<Transactions />}
                        />
                        <Route
                            path="*"
                            element={<NotFound />}
                        />
                    </Routes>
                </React.Suspense>
                <Location />
                {!cookiesAccepted && user?.id !== "recorder" && <CookiePolicy />}
            </BrowserRouter>
        </div>
    );
};

const Location: React.FC = () => {
    const location = useLocation();
    const errors = useReactiveVar(errorsVar);
    const success = useReactiveVar(successVar);
    const prevPageKey = usePrevious(location.key);

    React.useEffect(() => {
        if (location.key !== prevPageKey && prevPageKey !== undefined) {
            if (errors.length > 0) {
                errorsVar([]);
            }
            if (success !== null) {
                successVar(null);
            }
        }
    }, [prevPageKey, location, errors, success]);

    const renderPopup = (hash) => {
        switch (hash) {
            case "#manage-group":
                return <ManageGroup />;
            case "#manage-user":
                return <ManageUser />;
            case "#manage-task":
                return <ManageTask />;
            case "#task-info":
                return <TaskInfo />;
            case "#duel-info":
                return <DuelInfo />;
            case "#duel-video":
                return <DuelVideo />;
            case "#task-vote":
                return <TaskVote />;
            case "#send-complaint":
                return <SendComplaint />;
            case "#player-answer":
                return <PlayerAnswer />;
            case "#update-duelsInfo":
                return <UpdateDuelsInfo />;
            case "#organize-duel":
                return <OrganizeDuel />;
            case "#cancel-verification":
                return <CancelVerification />;
            case "#manage-englishName":
                return <ManageEnglishName />;
            case "#manage-purchase":
                return <EditPurchase />;
            case "#diploms":
                return <DiplomsModal />;
            case "#cookie-policy":
                return <CookiesPolicy />;
            case "#delete-group":
                return <DeleteGroup />;
            case "#add-user-in-group":
                return <AddUserInGroup />;
            case "#manage-instruction":
                return <ManageInstruction />;
            case "#delete-user-from-group":
                return <DeleteUserFromGroup />;
            default:
                return "";
        }
    };
    const hashList = [
        "#manage-group",
        "#manage-user",
        "#manage-task",
        "#task-info",
        "#duel-info",
        "#duel-video",
        "#task-vote",
        "#send-complaint",
        "#player-answer",
        "#update-duelsInfo",
        "#organize-duel",
        "#cancel-verification",
        "#manage-englishName",
        "#manage-purchase",
        "#diploms",
        "#cookie-policy",
        "#delete-group",
        "#add-user-in-group",
        "#manage-instruction",
        "#delete-user-from-group",
    ];

    if (hashList.includes(location.hash)) {
        return (
            <React.Suspense fallback={""}>
                <Popup
                    mode={
                        ["#task-info", "#send-complaint", "#player-answer"].includes(location.hash) ? "dark" : undefined
                    }
                    resizeDrag={location.hash === "#task-info"}
                    notUseClickOutside={location.hash === "#manage-instruction"}
                >
                    {renderPopup(location.hash)}
                </Popup>
            </React.Suspense>
        );
    }

    return <></>;
};

export default App;
