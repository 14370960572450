import _ from "lodash";
import { successErrorType } from "../cache/cache";
import { IRole, IUserDuelAccess } from "../types/types";

export const setError = (errors: successErrorType[], error: successErrorType[]): successErrorType[] => {
    return _.uniqWith([...errors, ...error], _.isEqual);
};

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()\[\]\\.,;:$\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(String(email).toLowerCase());
};

export const convertFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
    });
};

export const truncate = (str, len = 150) => {
    return str.length > len ? `${str.substring(0, len)}...` : str;
};

export const validURL = (str) => {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    ); // fragment locator
    return !!pattern.test(str);
};

export const validateYouTubeStreamKeyFormat = (key: string): boolean => {
    const pattern = /^[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}$/;
    return pattern.test(key);
};

/**
 * Escape special characters in the given string of text.
 *
 * @param  {string} string The string to escape for inserting into HTML
 * @return {string}
 * @public
 */

export const escapeHtml = (string) => {
    var str = "" + string;
    var match = /["'&<>]/.exec(str);

    if (!match) {
        return str;
    }

    var escape;
    var html = "";
    var index = 0;
    var lastIndex = 0;

    for (index = match.index; index < str.length; index++) {
        switch (str.charCodeAt(index)) {
            case 34: // "
                escape = "&quot;";
                break;
            case 38: // &
                escape = "&amp;";
                break;
            case 39: // '
                escape = "&#39;";
                break;
            case 60: // <
                escape = "&lt;";
                break;
            case 62: // >
                escape = "&gt;";
                break;
            default:
                continue;
        }

        if (lastIndex !== index) {
            html += str.substring(lastIndex, index);
        }

        lastIndex = index + 1;
        html += escape;
    }

    return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
};

/**
 * Склонение числительных
 * countTextFormatter(count, ['найдена', 'найдено', 'найдены'])
 * @param n
 * @param titles
 * @returns
 */
export const countTextFormatter = (n: number, titles: [string, string, string]) => {
    return titles[
        n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
    ];
};

export const getAccessToken = () => {
    return process.env.REACT_APP_STAGE === "production"
        ? localStorage.getItem("AccessToken")
        : sessionStorage.getItem("AccessToken");
};

export const setAccessToken = (token: string) => {
    process.env.REACT_APP_STAGE === "production"
        ? localStorage.setItem("AccessToken", token)
        : sessionStorage.setItem("AccessToken", token);
};

export const deleteCookie = (name: string) => {
    localStorage.removeItem("refreshAuthToken");
    sessionStorage.removeItem("refreshAuthToken");
};

export const getCookieValue = (key: string) => {
    return process.env.REACT_APP_STAGE === "production"
        ? localStorage.getItem("refreshAuthToken")
        : sessionStorage.getItem("refreshAuthToken");
};

export const setRefreshCookie = (value: string) => {
    process.env.REACT_APP_STAGE === "production"
        ? localStorage.setItem("refreshAuthToken", value)
        : sessionStorage.setItem("refreshAuthToken", value);
};

export const removeAccessToken = () => {
    localStorage.removeItem("AccessToken");
    sessionStorage.removeItem("AccessToken");
};

export const getLastMessageId = () => {
    return process.env.REACT_APP_STAGE === "production"
        ? localStorage.getItem("LastMessageId")
        : sessionStorage.getItem("LastMessageId");
};

export const setLastMessageId = (lastMessageId: string) => {
    process.env.REACT_APP_STAGE === "production"
        ? localStorage.setItem("LastMessageId", lastMessageId)
        : sessionStorage.setItem("LastMessageId", lastMessageId);
};

export const getWeekDay = (num: number | null | undefined) => {
    if (num === undefined || num === null || ![0, 1, 2, 3, 4, 5, 6].includes(num)) {
        return null;
    }
    let day: string = "";
    switch (num) {
        case 1:
            day = "Понедельник";
            break;
        case 2:
            day = "Вторник";
            break;
        case 3:
            day = "Среда";
            break;
        case 4:
            day = "Четверг";
            break;
        case 5:
            day = "Пятница";
            break;
        case 6:
            day = "Суббота";
            break;
        case 0:
            day = "Воскресенье";
            break;
    }
    return day;
};

export function capitalizeFLetter(str: string): string {
    return str[0].toUpperCase() + str.slice(1);
}

export function getDuelAccess(duelAccess: IUserDuelAccess | string, duelPrice: number): string {
    switch (duelAccess) {
        case "fee":
            return duelPrice + " евро";
        case "training":
            return "тренинг";
        case "membership":
            return "абонемент";
        case "free":
            return "бонусный поединок";
        case "free-role":
            return "бесплатно";
        default:
            return "0 евро";
    }
}

export function getDuelPayments(
    duelAccess: IUserDuelAccess,
    balance: number,
    freeRoles: IRole[],
    price?: number,
): { access: string; freeRoles: string[]; rolesWithAccess: string[] } {
    let access = "";
    console.log("getDuelPayments duelAccess: " + duelAccess);
    switch (duelAccess) {
        case "fee":
            access = "платно " + (price ? `(${price} EURO)` : `(Ваш баланс: ${balance} EURO)`);
            break;
        case "training":
            access = "по тренингу";
            break;
        case "membership":
            access = "по абонементу";
            break;
        case "free":
            access = "бонусный поединок";
            break;
        default:
            access = "0 евро";
    }

    const formattedFreeRoles = getUserFreeRoles(freeRoles);

    const allRoles = ["Судья", "Игрок", "Мастер"];

    return {
        access,
        freeRoles: formattedFreeRoles,
        rolesWithAccess: allRoles.filter((role) => !formattedFreeRoles.includes(role)),
    };
}

export function getUserFreeRoles(freeRoles: IRole[]): string[] {
    const roles = {
        judge1: "Судья",
        judge2: "Судья",
        judge3: "Судья",
        player1: "Игрок",
        player2: "Игрок",
        master: "Мастер",
    };
    return [...new Set(freeRoles.map((role) => roles[role]))];
}
